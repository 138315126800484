import React, { useState } from "react";
import "./App.css";

function App() {
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitMessage("");
        
        try {
            const response = await fetch("https://defaultbf4e7dfea49c4fed9b7899d4b40a25.9e.environment.api.powerplatform.com:443/powerautomate/automations/direct/workflows/b3a2af0c2500442abdfee0ff51f2b159/triggers/manual/paths/invoke/?api-version=1&tenantId=tId&environmentName=Default-bf4e7dfe-a49c-4fed-9b78-99d4b40a259e&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=PHjuko83YZn47-VyiGWfxzhYk_Gaojx02f9d0Hp8YzI", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });
            
            if (response.ok) {
                setIsSubmitted(true);
                setSubmitMessage("");
                setEmail("");
            } else {
                setSubmitMessage("Une erreur est survenue. Veuillez réessayer.");
            }
        } catch (error) {
            setSubmitMessage("Une erreur est survenue. Veuillez réessayer.");
            console.error("Erreur:", error);
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <div className="container">
            <div className="content">
                <h1>Klaire</h1>
                <p className="tagline">L'intelligence artificielle pour le notariat</p>
                
                <div className="form-container">
                    {isSubmitted ? (
                        <div className="thank-you-message">
                            <h2>Merci pour votre inscription !</h2>
                            <p>Nous reviendrons vers vous prochainement.</p>
                            <p className="submit-message">{submitMessage}</p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="contact-form">
                            <h2>Rejoignez notre liste d'attente</h2>
                            <p>Soyez parmi les premiers à accéder à Klaire</p>
                            
                            <div className="form-group">
                                <input 
                                    type="email" 
                                    id="email" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Votre adresse email"
                                />
                            </div>
                            
                            <button 
                                type="submit" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Traitement..." : "Rejoindre la liste"}
                            </button>
                            
                            {submitMessage && <p className="submit-message">{submitMessage}</p>}
                        </form>
                    )}
                </div>
                
                <div className="company-info">
                    <p>KLAIRE SAS • 7 RUE NOTRE-DAME DE BONNE NOUVELLE 75002 PARIS • contact@klaire.cc</p>
                </div>
            </div>
        </div>
    );
}

export default App;
